import { render, staticRenderFns } from "./stock-chart-card.vue?vue&type=template&id=4a124688&scoped=true"
import script from "./stock-chart-card.vue?vue&type=script&lang=js"
export * from "./stock-chart-card.vue?vue&type=script&lang=js"
import style0 from "./stock-chart-card.vue?vue&type=style&index=0&id=4a124688&prod&lang=scss&scoped=true"
import style1 from "./stock-chart-card.vue?vue&type=style&index=1&id=4a124688&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a124688",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LineChart: require('/codebuild/output/src3271430876/src/web/components/line-chart.js').default,SkeletonChart: require('/codebuild/output/src3271430876/src/web/components/skeleton/skeleton-chart.vue').default})
